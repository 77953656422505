export const DEFAULT_ARTICLE_META_IMAGE = 'https://r.fashionunited.com/bcT__nOeCx9860OtIz-YuSvCFHEaRSocMWDjceGEyv4/resize:fill:1200:630:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9mdV9sb2dvLlF4MkRuQ2RqOHhuRFFHMmFiLmpwZw';
export const DEFAULT_EVENT_IMAGES = [
  'https://r.fashionunited.com/Ilimun64SeMjJ4SSwRSW4VbtM4mhqZRbB42bRNkbY88/resize:fill:42:28:0/gravity:ce/quality:10/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9mdV9sb2dvLlF4MkRuQ2RqOHhuRFFHMmFiLmpwZw',
  'https://r.fashionunited.com/QyJ1HXRFIjmC9BlY5psuN4qfwkRoFfCQ9Bejjan-WRo/resize:fill:375:250:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9mdV9sb2dvLlF4MkRuQ2RqOHhuRFFHMmFiLmpwZw',
  'https://r.fashionunited.com/xKb9q2ZjhgCJENOue8E2UiidCIpabUgS6LNPa6wgcA4/resize:fill:414:276:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9mdV9sb2dvLlF4MkRuQ2RqOHhuRFFHMmFiLmpwZw',
  'https://r.fashionunited.com/h3m06IVmYjAJzvGasoIAVbdqUNgXQF6ps_u8fpweYbQ/resize:fill:720:0:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9mdV9sb2dvLlF4MkRuQ2RqOHhuRFFHMmFiLmpwZw',
];
export const categories = [8, 9, 10, 11, 12, 13, 27, 36, 38];

export const metaImageDimensions = { width: 1200, height: 630 }; // source: https://developers.facebook.com/docs/sharing/webmasters/images/

export const partners = {
  'fashionunited.in': 20_228,
  'fashionunited.ca': 8705,
  'fashionunited.ca/fr': 12_817,
  'fashionunited.com': 21_925,
  'au.fashionunited.com': 7400,
  'fashionunited.be/fr': 15_321,
  'fashionunited.be': 24_607,
  'fashionunited.ch': 17_023,
  'fashionunited.cn': 2708,
  'fashionunited.co': 25_801,
  'fashionunited.nz': 7154,
  'fashionunited.cl': 25_949,
  'fashionunited.com.ar': 25_835,
  'fashionunited.com.pe': 25_892,
  'fashionunited.de': 30_729,
  'fashionunited.es': 29_303,
  'fashionunited.fr': 19_717,
  'fashionunited.it': 19_094,
  'fashionunited.mx': 25_927,
  'fashionunited.nl': 41_154,
  'fashionunited.ru': 24_614,
  'fashionunited.uk': 41_172,
  'fashionunited.ie': 86_525,
  'fashionunited.com.br': 86_525,
  'fashionunited.jp': 86_525,
  'fashionweekweb.com': 86_525,
};

export const amountOfDays = 70;
export const repostsProductIds = [4, 6, 8];
export const jobPublicationsProductIds = [3, 5, 7];
