import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import LayoutComponent, {
  layoutPropertyTypes,
  LayoutProperties,
} from '@fuww/library/src/Layout';
import DynamicEmailConfirmationAlert
  from './Users/DynamicEmailConfirmationAlert';
import DynamicNavigation from './DynamicNavigation';
import DynamicFooter from './DynamicFooter';
import hiddenLoading from '../lib/hiddenLoading';
import { UserContext } from './UserProvider';

const DynamicGoogleOneTap = dynamic(
  () => import('./LoginSignup/GoogleOneTapProvider'), {
    ssr: false,
    loading: hiddenLoading,
  },
);

const Layout = ({
  isExecutive,
  children,
  ...properties
}: LayoutProperties) => {
  const { user, loading } = useContext(UserContext);

  return (
    <LayoutComponent
      navigation={<DynamicNavigation isExecutive={isExecutive ?? false} />}
      footer={<DynamicFooter />}
      sitewideAlert={(<DynamicEmailConfirmationAlert />)}
      isExecutive={isExecutive ?? false}
      enableReactiveNavbar
      {...properties}
    >
      {children}
      {!user && !loading && <DynamicGoogleOneTap />}
    </LayoutComponent>
  );
};

Layout.propTypes = layoutPropertyTypes;

export default Layout;
